import "./app.css";
import {useEffect, useState} from "react";
import useLocalStorage from "./useLocalStorage";

const DENOMINATIONS = [500, 200, 100, 50, 20, 10, 5, 2, 1, .5, .2, .1, .05, .02, .01]
const DATE_FORMAT_OPTIONS = {
	hour: '2-digit',
	minute: '2-digit',
	second: '2-digit',
	year: 'numeric',
	month: 'numeric',
	day: 'numeric',
}

function Item({id, name, description, tags, price, onClick}) {
	return <div className="card cursor-pointer hover:scale-110 transition-transform flex-1" onClick={() => { onClick?.() }}>
		<h4>{name}</h4>
		{description && <p>{description}</p>}

		<div className="flex gap-2">
			{tags && tags.map(tag => <span className="rounded-full text-sm bg-slate-500 border-slate-400 border-2 px-2">{tag}</span>)}
		</div>
		<span>{formatPrice(price)}</span>
	</div>;
}

export default function App() {
	const [items, setItems] =  useState([])
	const [cart, setCart] =  useState([])//{count, item}
	const [currentPayment, setCurrentPayment] =  useState([])//{count, value}
	const [modalOpen, setModalOpen] =  useState(false)
	const [historyOpen, setHistoryOpen] =  useState(false)

	const [history, setHistory] = useLocalStorage("kasse-history", [])

	function addToCart(item) {
		const index = cart.findIndex((e) => e.item.id === item.id)

		if (index === -1) {
			setCart([...cart, {item: item, count: 1}])
		} else {
			setCart(cart.map(i => i.item.id === item.id ? {...i, count: (i.count + 1)} : i))
		}
	}

	function removeFromCart(id) {
		const nextCart = cart.map(i => {
				if (i.item.id !== id) {
					return i
				} else {
					if (i.count > 1) {
						return {...i, count: (i.count - 1)}
					} else {
						return null
					}
				}
			})
			.filter(e => e !== null)

		setCart(nextCart)
	}

	function addPayment(value) {
		if (cart.findIndex((e) => e.value === value) === -1) {
			setCurrentPayment([...currentPayment, {value: value, count: 1}])
		} else {
			setCurrentPayment(currentPayment.map(i => i.value === value ? {...i, count: (i.count + 1)} : i))
		}
	}

	function getReturn() {
		let remainder = getCurrentPaymentSum() - getCartSum();

		const out = []

		for (const value of DENOMINATIONS) {
			if (remainder >= value) {
				out.push({value: value, count: Math.floor(remainder / value)})
				remainder %= value
			}
		}

		return out
	}

	const getCartSum = () => cart.reduce((acc, cur) => acc + (cur.item.price * cur.count), 0);
	const getCurrentPaymentSum = () => currentPayment.reduce((acc, cur) => acc + (cur.value * cur.count), 0);
	const getHistorySum = () => history.reduce((acc, cur) => acc + cur.value, 0);


	useEffect(() => {
		fetch('/items.json')
			.then(res => res.json())
			.then(data => {
				setItems(data)
			})
			.catch(err => {
				console.log("Error loading data", err)
			})
	}, []);

	return <div className="flex gap-8 h-screen p-8">
		<div className="basis-4/5 flex gap-4 flex-wrap content-start">
			{items.map(item => <Item key={item.id} {...item} onClick={() => { addToCart(item) }}/>)}
		</div>

		<div className="basis-1/5 flex flex-col h-full border-l-2 border-slate-400 ps-4">
			<h2 className="mb-4">Einkaufswagen</h2>
			<ul className="mb-auto max-h-[90%] overflow-y-auto">
				{cart.map(cartItem => <li key={cartItem.item.id} className="w-full card relative mb-2 overflow-y-auto">
					<span className="font-bold">{cartItem.item.name}</span>

					<div className="flex justify-between">
						<span>{cartItem.count}x {formatPrice(cartItem.item.price)}</span>

						<span>{formatPrice(cartItem.count * cartItem.item.price)}</span>
					</div>

					<button className="absolute top-2 right-2 w-6 h-6 bg-red-500 rounded-sm" onClick={() => { removeFromCart(cartItem.item.id) }}>-</button>
				</li>)}

				{cart.length === 0 && <li>Einkaufswagen ist leer</li>}
			</ul>

			<h3 className="my-4">Sum: {formatPrice(getCartSum())}</h3>

			<div className="flex gap-2">
				<button className="bg-blue-500" disabled={cart.length === 0} onClick={() => { setModalOpen(true) }}>Bezahlen</button>
				<button className="bg-red-500" disabled={cart.length === 0} onClick={() => { setCart([]) }}>Löschen</button>
				<button className="bg-gray-500" onClick={() => { setHistoryOpen(true) }}>Verlauf</button>
			</div>
		</div>

		{modalOpen && <>
			<div className="w-full h-full z-10 fixed inset-0 backdrop-blur-sm flex items-center justify-center">
				<div className="bg-slate-700 w-2/3 rounded-xl relative p-4">
					<div className="flex justify-between !h-32">
						<div>
							<h3>Zu zahlen: {formatPrice(getCartSum())}</h3>
							<h3>Gegeben: {formatPrice(getCurrentPaymentSum())}</h3>
							<h3>Offen: {formatPrice(getCartSum() - getCurrentPaymentSum())}</h3>
						</div>

						<div className="flex-col">
							<h3 className="text-center">Zurück</h3>

							<div className="flex gap-2">
								{getReturn().map(ret => <div className="flex flex-col rounded-xl bg-slate-500 w-20 h-20 text-center justify-center">
									<span><b>{ret.count}</b>x</span> <b>{formatPrice(ret.value)}</b>
								</div>)}
							</div>
						</div>
					</div>

					<div className="grid grid-cols-3 gap-2">
						{DENOMINATIONS.map(val => <button className="cashButton" onClick={() => { addPayment(val) }}>{val}€</button>)}

						<button className="cashButton !bg-red-500" onClick={() => {
							setCurrentPayment([])
						}}>CLR
						</button>
						<button
							className="cashButton !bg-lime-500"
							disabled={getCurrentPaymentSum() < getCartSum()}
					        onClick={() => {
						        setHistory([...history, {ts: new Date().getTime(), value: getCartSum(), items: cart}])

						        setCart([])
						        setCurrentPayment([])
						        setModalOpen(false)
					        }}>
							Fertig
						</button>

						<button className="cashButton !bg-blue-500" onClick={() => {
							setHistory([...history, {ts: new Date().getTime(), value: getCartSum(), items: cart}])

							setCart([])
							setCurrentPayment([])
							setModalOpen(false)
						}}>Exact</button>

						<button className="cashButton absolute bottom-2 right-2" onClick={() => {setModalOpen(false)}}>Close</button>
					</div>
				</div>
			</div>
		</>}

		{historyOpen && <>
			<div className="w-full h-full z-10 fixed inset-0 backdrop-blur-sm flex items-center justify-center">
				<div className="bg-slate-700 w-2/3 rounded-xl relative p-4">
					<h3>Verlauf</h3>

					<div className="flex gap-8">
						<h3>Anzahl: {history.length}</h3>
						<h3>Summe: {formatPrice(getHistorySum())}</h3>
					</div>

					<button className="absolute top-2 right-2 w-16 h-8 bg-slate-500" onClick={() => { setHistoryOpen(false) }}>Close</button>

					<div className="flex flex-wrap content-start gap-2">
						{history.sort((a,b) => a.ts < b.ts).map(hist => <div className="card">
							<h4>{formatPrice(hist.value)}</h4>

							<ul>
								{hist.items.map(it => <li>
									{it.count}x{it.item.name}
								</li>)}
							</ul>

							<span>{new Date(hist.ts).toLocaleDateString('de-DE', DATE_FORMAT_OPTIONS)}</span>
						</div>)}
					</div>
				</div>
			</div>
		</>}
	</div>;
}

const euroFormat = Intl.NumberFormat('de-DE', {
	style: 'currency',
	currency: 'EUR',
});

const formatPrice = (price) => euroFormat.format(price)
